/* TeamCard.css */
/* Styles spécifiques à chaque carte d'équipe */

.team-card {
    border: 1px solid var(--border-color) !important;
    border-bottom: 2px solid var(--border-color) !important;
    border-radius: 5px;
    background-color: var(--white);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.team-card-header {
    display: flex;
    align-items: flex-start;
    padding: 1.5rem;
    background-color: var(--category-bg);
    border-bottom: 1px solid var(--border-color);
}

.team-card-image-wrapper {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
    flex-shrink: 0;
}

.team-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.team-card-info {
    flex-grow: 1;
    min-width: 0; /* Allows text to wrap properly */
}

.team-card-name {
    margin-bottom: 0.25rem;
    font-size: 1.25rem;
    font-weight: 600;
    word-wrap: break-word;
    hyphens: auto;
}

.team-card-name a {
    color: var(--text-color);
    text-decoration: none;
}

.team-card-name a:hover {
    color: var(--primary-color);
}

.team-card-role {
    color: var(--secondary-color);
    font-size: 0.9rem;
    margin-bottom: 0;
    word-wrap: break-word;
    hyphens: auto;
}

.team-card .card-body {
    flex-grow: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.team-card .list-group {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.team-card .list-group-item {
    border: none;
    padding: 0.5rem 0;
    display: flex;
    align-items: flex-start;
    word-break: break-word;
}

.team-card .list-group-item i {
    margin-right: 0.5rem;
    color: var(--secondary-color);
    width: 20px;
    text-align: center;
    flex-shrink: 0;
}

.team-card .list-group-item a {
    color: var(--text-color);
    text-decoration: none;
}

.team-card .list-group-item a:hover {
    color: var(--primary-color);
}

.team-card .card-footer {
    background-color: var(--white);
    border-top: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    flex-wrap: wrap;
}

.team-card .btn-link {
    color: var(--text-color);
    padding: 0;
    text-decoration: none;
}

.team-card .btn-link:hover {
    color: var(--primary-color);
}

.team-card .btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.team-card .btn-primary:hover {
    background-color: #004494;
    border-color: #004494;
}

@media (max-width: 576px) {
    .team-card-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .team-card-image-wrapper {
        margin-right: 0;
        margin-bottom: 1rem;
    }

    .team-card-info {
        width: 100%;
    }

    .team-card .card-footer {
        justify-content: center;
    }

    .team-card .card-footer .btn {
        margin: 0.5rem;
    }
}