.comment {
    position: relative;
    width: 100%;
    background-color: var(--white);
    padding: 16px;
    margin-bottom: 16px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    transition: background-color 0.2s ease;
}

.comment:hover {
    background-color: var(--hover-color);
}

.comment-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
}

.comment-avatar {
    width: 36px;
    height: 36px;
    border: 2px solid var(--border-color);
}

.comment a {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--text-color);
    text-decoration: none;
    font-weight: 600;
    font-size: 0.95rem;
}

.comment a:hover {
    color: var(--primary-color);
}

.comment-body {
    margin: 0;
    color: var(--text-color);
    line-height: 1.5;
    white-space: pre-line;
    font-size: 0.95rem;
}

.comment .comment-date {
    color: var(--secondary-color);
    font-size: 0.85rem;
    margin-left: auto;
}

.comment-actions {
    margin-left: 12px;
}

.delete-btn {
    color: var(--secondary-color) !important;
    transition: color 0.2s ease !important;
    padding: 4px 8px !important;
    border-radius: 4px;
}

.delete-btn:hover {
    color: var(--danger-color) !important;
    background-color: rgba(var(--danger-rgb), 0.1);
}

.delete-btn::after {
    display: none;
}

.dropdown-menu {
    min-width: 140px;
    padding: 4px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.delete-item {
    color: var(--text-color) !important;
    padding: 8px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9rem;
}

.delete-item:hover {
    background-color: rgba(var(--danger-rgb), 0.1);
    color: var(--danger-color) !important;
}

.delete-item i {
    color: inherit;
    font-size: 1rem;
}

/* Modal customization */
.modal-content {
    border-radius: 8px;
    border: none;
}

.modal-header {
    padding: 16px 20px;
    border-bottom: 1px solid var(--border-color);
}

.modal-body {
    padding: 20px;
    color: var(--text-color);
}

.modal-footer {
    padding: 16px 20px;
    border-top: 1px solid var(--border-color);
}

.comment-modal {
    background-color: rgba(0, 0, 0, 0.5);
}