.note-information {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 10px;
}

.note-information-table {
    width: 100%;
    border-collapse: collapse;
}

.note-information-table th, .note-information-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.note-information-table th {
    background-color: #f2f2f2;
    color: black;
}