.ir-details {
    padding: 20px;
  }
  
  .ir-details .detail-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  
  .ir-details .detail-item .icon {
    margin-right: 10px;
    margin-top: 3px;
    color: var(--secondary-color);
  }
  
  .ir-details .detail-item small {
    display: block;
    color: var(--secondary-color);
    font-size: 0.75rem;
    margin-bottom: 2px;
  }
  
  .ir-details .detail-item p {
    margin: 0;
    font-weight: 500;
    color: var(--text-color);
  }
  
  .ir-details h6 {
    color: var(--secondary-color);
    font-weight: 600;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 10px;
  }
  
  .ir-details .ir-title {
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 20px;
    font-size: 1.25rem;
  }
  
  .ir-details .ir-title i {
    color: var(--secondary-color);
    margin-right: 10px;
  }