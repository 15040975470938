.no-border-card {
    border: none !important;
    box-shadow: none !important;
    max-width: 60%;
}

.init-card-title {
    font-size: 5rem !important;
    color: #f9cad5 !important;
    font-family: 'Assistant', sans-serif !important;     
}

.init-card-icon {
    font-size: 5rem;
}

.init-card-content {
    font-size: 1rem;
    color: #4e4e4e;
    font-family: 'Inter', sans-serif;
}
.init-card-button {
    font-family: 'Inter', sans-serif;
}

.init-card-content-br {
    margin-bottom: 50px;
}