.upload-zone {
    border: 2px dashed #dee2e6;
    border-radius: 0.5rem;
    padding: 1.5rem;
    text-align: center;
    transition: all 0.3s ease;
    cursor: pointer;
}

.upload-zone:hover:not(.upload-zone-loading) {
  border-color: var(--bs-primary);
  background-color: #f8f9fa;
}

.upload-zone.dragging {
  border-color: var(--bs-primary);
  background-color: rgba(var(--bs-primary-rgb), 0.1);
}

.upload-zone.upload-zone-loading {
  pointer-events: none;
  opacity: 0.6;
}

.upload-icon {
  font-size: 4rem;
  color: #adb5bd;
  transition: color 0.3s ease;
}

.upload-zone.dragging .upload-icon {
  color: var(--bs-primary);
}

.file-input {
  display: none;
}

.description-text {
  color: #6c757d;
  margin-bottom: 0;
  transition: all 0.3s ease;
}

.selected-file {
  color: var(--bs-primary);
  font-weight: 500;
}

.progress-container {
  margin-top: 1rem;
}

.progress-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.alert-container {
  margin-top: 1rem;
}

.info-list {
  margin: 0;
  padding-left: 1.2rem;
}

.info-list li {
  margin-top: 0.25rem;
}

.modal-title-icon {
  margin-right: 0.5rem;
  color: var(--bs-primary);
}