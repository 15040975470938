/* Workflow.css */

:root {
    --primary-color: #3498db;
    --secondary-color: #2ecc71;
    --text-color: #2c3e50;
    --background-color: #f8f9fa;
    --border-color: #e0e0e0;
    --hover-color: #ecf0f1;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
    color: var(--text-color);
    font-weight: 600;
}

/* Cards */
.card {
    border: 1px solid var(--border-color);
    border-bottom: 2px solid var(--border-color) !important;
    border-radius: 8px;
    transition: box-shadow 0.3s ease;
}

.card:hover {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.card-header {
    background-color: var(--background-color);
    border-bottom: 1px solid var(--border-color);
    font-weight: 600;
    color: var(--text-color);
}

/* Buttons */
.btn {
    border-radius: 4px;
    font-weight: 500;
    transition: all 0.3s ease;
}

.btn:hover {
    transform: translateY(-1px);
}

/* Badges */
.badge {
    font-size: 0.85rem;
    padding: 0.4em 0.6em;
    border-radius: 4px;
}

/* Progress bars */
.progress {
    height: 10px;
    border-radius: 5px;
    background-color: var(--border-color);
    overflow: hidden;
}

.progress-bar {
    background-color: var(--secondary-color);
    transition: width 0.6s ease;
}

/* List groups */
.list-group-item {
    border: none;
    border-bottom: 1px solid var(--border-color);
    padding: 1rem 1.5rem;
}

.list-group-item:last-child {
    border-bottom: none;
}

/* Icons */
.icon {
    margin-right: 0.5rem;
}

/* Workflow specific styles */
.workflow-container {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
}

.cycles-card {
    margin-top: 20px;
}

.cycles-title {
    color: var(--text-color);
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

.workflow-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: separate;
    border-spacing: 0;
}

.workflow-table th {
    background-color: var(--background-color);
    color: var(--text-color);
    font-weight: 600;
    text-transform: uppercase;
    padding: 12px;
    font-size: 0.9rem;
}

.workflow-table td {
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid var(--border-color);
}

.workflow-table tbody tr:hover {
    background-color: var(--hover-color);
}

.cycle-row {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cycle-row:hover {
    background-color: var(--hover-color);
}

.status-badge {
    font-weight: 500;
}

/* Modal styles */
.modal-fullscreen .modal-dialog {
    max-width: 100%;
    margin: 0;
    height: 100%;
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}

.modal-fullscreen .modal-body {
    overflow-y: auto;
    padding: 0;
}

.procedure-modal .modal-header,
.procedure-detail-modal .modal-header {
    background-color: var(--background-color);
    color: var(--text-color);
}

.procedure-modal .modal-title,
.procedure-detail-modal .modal-title {
    font-size: 1.2rem;
    font-weight: 600;
}

/* Toggle Details Button */
.toggle-details-btn {
    transition: all 0.3s ease-in-out;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}

.toggle-details-btn:hover {
    background-color: var(--primary-color);
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .workflow-table {
        font-size: 0.9rem;
    }

    .workflow-table th,
    .workflow-table td {
        padding: 8px;
    }

    .btn {
        font-size: 0.9rem;
        padding: 0.375rem 0.75rem;
    }
}