/* FECViewer.css */

.fec-viewer-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
 }
 
 .fec-summary {
  border: none;
  background-color: #fff;
 }
 
 .fec-summary .card-body {
  padding: 1.5rem;
 }
 
 .stat-item {
  padding: 0.5rem 1rem;
  background-color: #f8f9fa;
  border-radius: 6px;
  height: 100%;
 }
 
 .stat-label {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #6c757d;
  font-weight: 600;
  margin-bottom: 0.25rem;
 }
 
 .stat-value {
  font-size: 1rem;
  font-weight: 500;
  color: #2c3e50;
 }
 
 .badge {
  font-weight: 500;
  padding: 0.5em 0.8em;
 }
 
 /* Header Styling */
 .fec-viewer-header {
  background-color: #fff;
  padding: 16px 24px;
  border-bottom: 1px solid #e9ecef;
 }
 
 .fec-viewer-header .form-control {
  max-width: 300px;
  border-radius: 6px;
  border: 1px solid #dee2e6;
  padding: 8px 12px;
  padding-left: 32px;
  font-size: 0.9rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%236c757d' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 10px center;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }
 
 .fec-viewer-header .form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
 }
 
 /* Content Styling */
 .fec-viewer-content {
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  padding: 0;
  background-color: #fff;
 }
 
 /* Table Styling */
 .fec-viewer-content .table {
  margin-bottom: 0;
  font-size: 0.9rem;
 }
 
 .fec-viewer-content .table thead th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #495057;
  padding: 12px 16px;
  border-top: none;
  border-bottom: 2px solid #dee2e6;
  z-index: 1;
 }
 
 .fec-viewer-content .table td {
  padding: 12px 16px;
  vertical-align: middle;
  border-color: #e9ecef;
  color: #2c3e50;
 }
 
 .fec-viewer-content .table tbody tr {
  transition: background-color 0.15s ease-in-out;
 }
 
 .fec-viewer-content .table tbody tr:hover {
  background-color: rgba(0, 123, 255, 0.03);
 }
 
 /* Numeric columns alignment */
 .fec-viewer-content .table td[data-type="number"],
 .fec-viewer-content .table th[data-type="number"] {
  text-align: right;
 }
 
 /* Footer Styling */
 .fec-viewer-footer {
  background-color: #fff;
  padding: 16px 24px;
  border-top: 1px solid #e9ecef;
 }
 
 /* Entries count */
 .fec-viewer-footer small {
  color: #6c757d;
  font-size: 0.85rem;
 }
 
 /* Pagination Styling */
 .fec-viewer-footer .pagination {
  margin-bottom: 0;
 }
 
 .fec-viewer-footer .page-item .page-link {
  padding: 8px 12px;
  color: #495057;
  border-color: #dee2e6;
  font-size: 0.9rem;
 }
 
 .fec-viewer-footer .page-item.active .page-link {
  background-color: #0d6efd;
  border-color: #0d6efd;
  color: #fff;
 }
 
 .fec-viewer-footer .page-item:not(.active) .page-link:hover {
  background-color: #e9ecef;
  border-color: #dee2e6;
  color: #0d6efd;
 }
 
 .fec-viewer-footer .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
 }
 
 /* Loading State */
 .fec-viewer-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
 }
 
 .fec-viewer-loading .spinner-border {
  color: #0d6efd;
  width: 3rem;
  height: 3rem;
 }
 
 /* Error State */
 .fec-viewer-error {
  padding: 24px;
  text-align: center;
  color: #dc3545;
 }
 
 /* Responsive Design */
 @media (max-width: 768px) {
  .fec-viewer-header {
    padding: 12px 16px;
  }
 
  .fec-viewer-header .form-control {
    max-width: 100%;
  }
 
  .fec-viewer-content .table thead th,
  .fec-viewer-content .table td {
    padding: 8px 12px;
    font-size: 0.85rem;
  }
 
  .fec-viewer-footer {
    padding: 12px 16px;
  }
 }
 
 /* Custom Scrollbar - matching FileViewerModal.css */
 .fec-viewer-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
 }
 
 .fec-viewer-content::-webkit-scrollbar-track {
  background: #f1f1f1;
 }
 
 .fec-viewer-content::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
 }
 
 .fec-viewer-content::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
 }
 
 /* Firefox Scrollbar */
 .fec-viewer-content {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
 }
 
 .table-responsive {
  height: 100%;
 }