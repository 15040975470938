:root {
    --border-color: #e0e0e0;
    --border-bottom-color: #ced4da;
    --background-color: #f8f9fa;
    --text-color: #495057;
    --hover-color: #e9ecef;
}

.wscardmenu {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-bottom: 2px solid var(--border-bottom-color);
    border-radius: 5px;
    color: var(--text-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    height: 60px;
    transition: background-color 0.3s ease;
}

.wscardmenu:hover {
    background-color: var(--hover-color);
}

.wscardmenu span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    padding: 0 10px;
}