/* ProcedureDetailsRight.css */

.procedure-details-right {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    color: #333;
    background-color: #f8f9fa;
}

.section-title, .section-title-right {
    font-size: 1.25rem;
    color: #2c3e50;
    margin-bottom: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 0.5rem;
}

.status-section, .document-section, .info-section {
    padding: 1.5rem;
}

.status-priority-display {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.status-badge, .priority-badge {
    font-size: 1rem;
    padding: 0.5em 1em;
    border-radius: 20px;
    font-weight: 500;
    text-transform: capitalize;
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.info-item {
    display: flex;
    flex-direction: column;
}

.info-item .status-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.info-label {
    font-size: 0.875rem;
    color: #7f8c8d;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.info-value {
    font-size: 1rem;
    font-weight: 500;
}

.action-button-container {
    margin-top: 1.5rem;
}

.action-button {
    font-weight: 500;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    transition: all 0.3s ease;
    border: none !important;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    width: 100%;
    color: white;
}

.action-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.action-button.start { background-color: #27ae60; }
.action-button.review { background-color: #3498db; }
.action-button.complete { background-color: #9b59b6; }
.action-button.back, .action-button.reopen { background-color: #f39c12; }

.document-manager .list-group-item {
    border: none;
    padding: 0;
    background-color: transparent !important;
    transition: background-color 0.3s ease;
}

.document-manager .list-group-item:last-child {
    border-bottom: none;
}

.document-manager .btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 4px;
}

.status-back-button {
    min-width: 24px;
    height: 24px;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #e9ecef;
    border: none;
    color: #495057;
    transition: all 0.2s ease;
}

.status-back-button:hover {
    background-color: #dee2e6;
    color: #212529;
    transform: translateX(-2px);
}

.status-back-button:active {
    background-color: #ced4da;
}

.status-back-button i {
    font-size: 0.8rem;
}

/* Modification de info-value pour le statut */
.info-value.with-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.info-value .status-text {
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {
    .procedure-details-right {
        padding: 1rem;
    }

    .section-title, .section-title-right {
        font-size: 1.1rem;
    }

    .status-priority-display {
        flex-direction: column;
        align-items: flex-start;
    }

    .info-grid {
        grid-template-columns: 1fr;
    }
}

.status-container {
    border-radius: 8px;
    padding: 1rem;
}

.current-status {
    font-weight: 500;
}

.status-value {
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.status-action-button {
    font-weight: 500;
    min-width: 150px;
    letter-spacing: 0.5px;
    transition: all 0.3s ease;
}

.status-action-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.status-action-button:disabled {
    transform: none;
    box-shadow: none;
}