/* Modal backdrop styling */
.attributes-modal {
    max-width: 800px;
}

.attributes-modal-content {
    border: none;
    border-radius: 8px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

/* Modal header */
.attributes-modal .modal-header {
    padding: 1.25rem 1.5rem;
    background-color: #f8f9fa;
    border-bottom: 1px solid #eaeaea;
    border-radius: 8px 8px 0 0;
}

.attributes-modal-title {
    font-size: 1.25rem;
    font-weight: 500;
    color: #2c3e50;
    display: flex;
    align-items: center;
}

.attributes-modal .modal-header .close {
    padding: 1.25rem;
    margin: -1rem -1rem -1rem auto;
}

/* Modal body */
.attributes-modal-body {
    padding: 1.5rem;
}

/* Table styling */
.attributes-modal table {
    margin-bottom: 0;
}

.attributes-modal table thead th {
    background-color: #f8f9fa;
    font-weight: 500;
    color: #495057;
    border-bottom: 2px solid #dee2e6;
    padding: 0.75rem;
}

.attributes-modal table tbody td {
    padding: 0.75rem;
    vertical-align: middle;
}

/* Type badge styling */
.attribute-type {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    border-radius: 20px;
    background-color: #e9ecef;
    color: #495057;
    text-transform: capitalize;
}

/* Value styling */
.attribute-value {
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, monospace;
    color: #0d6efd;
}

/* Name styling */
.attribute-name {
    font-weight: 500;
    color: #2c3e50;
}

/* Hover effect on rows */
.attributes-modal table tbody tr:hover {
    background-color: #f8f9fa;
}

/* Custom scrollbar for the modal body */
.attributes-modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.attributes-modal-body::-webkit-scrollbar {
    width: 6px;
}

.attributes-modal-body::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
}

.attributes-modal-body::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 3px;
}

.attributes-modal-body::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}

.update-attribute-icon {
    font-size: 0.8rem;
    color: #9c9c9c;
}

.attribute-value-container {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding: 6px 8px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.attribute-value-container:hover {
    background-color: #f8f9fa;
}

.attribute-display-value {
    margin-right: 8px;
}

/* Edit button styling */
.edit-action-button {
    background: transparent;
    border: none;
    padding: 6px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6c757d;
    transition: all 0.2s ease;
}

.edit-action-button:hover {
    background-color: #e9ecef;
    color: #0d6efd;
}

/* Edit mode container */
.attribute-edit-container {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}

/* Input field styling */
.attribute-edit-input {
    flex-grow: 1;
    padding: 8px 12px;
    border: 2px solid #e9ecef;
    border-radius: 4px;
    font-size: 0.9375rem;
    transition: border-color 0.2s ease;
    background-color: white;
}

.attribute-edit-input:focus {
    outline: none;
    border-color: #0d6efd;
    box-shadow: 0 0 0 2px rgba(13, 110, 253, 0.1);
}

/* Edit actions container */
.attribute-edit-actions {
    display: flex;
    gap: 4px;
}

/* Save button specific styling */
.save-button {
    color: #198754;
}

.save-button:hover {
    background-color: #d1e7dd;
    color: #0a3622;
}

/* Cancel button specific styling */
.cancel-button {
    color: #dc3545;
}

.cancel-button:hover {
    background-color: #f8d7da;
    color: #842029;
}

/* Icons styling */
.edit-action-button i {
    font-size: 1rem;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Animation for edit mode transition */
.attribute-edit-container,
.attribute-value-container {
    animation: fadeIn 0.2s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}