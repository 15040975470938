.dm-container {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.dm-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5rem;
}

.dm-subtitle {
    font-size: 0.875rem;
    color: #495057;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    margin: 0;
}

.dm-add-btn {
    color: #007bff;
    font-size: 1.2rem;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.2s ease;
}

.dm-add-btn:hover {
    color: #2d3436;
}

.dm-document-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #ffffff;
    border: 1px solid rgba(0,0,0,0.08);
    border-radius: 8px;
    margin-bottom: 0.75rem;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0,0,0,0.02);
}

.dm-document-item:hover {
    background-color: #f8f9fa;
    border-color: rgba(0,0,0,0.12);
    box-shadow: 0 4px 8px rgba(0,0,0,0.04);
    transform: translateY(-1px);
}

.dm-document-name {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: #2d3436;
    flex-grow: 1;
    cursor: pointer;
}

.dm-actions {
    display: flex;
    gap: 1rem;
    margin-left: 1rem;
}

.dm-action-btn {
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 6px;
}

.dm-action-btn--send, .dm-action-btn--list, .dm-action-btn--request {
    color: #007bff;
}

.dm-action-btn--send:hover, .dm-action-btn--list:hover, .dm-action-btn--request:hover {
    color: #0056b3;
    background-color: rgba(0, 123, 255, 0.1);
    cursor: pointer;
}

.dm-action-btn--delete {
    color: #dc3545;
}

.dm-action-btn--delete:hover {
    color: #c82333;
    background-color: rgba(220, 53, 69, 0.1);
}

.dm-action-btn--done {
    color: #198754;
    cursor: default;
    pointer-events: none;
}


.dm-empty {
    color: #6c757d;
    font-style: italic;
    text-align: center;
    padding: 1.5rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    margin: 1rem 0;
    border: 1px dashed #dee2e6;
}

.dm-processing {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: #e8f4ff;
    border-radius: 8px;
    margin-bottom: 1rem;
    border-left: 3px solid #007bff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.02);
}

.dm-request-btn {
    width: 100%;
    text-align: left;
    padding: 1.25rem;
    margin-bottom: 0.75rem;
    background-color: #ffffff;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    transition: all 0.2s ease;
    position: relative;
    overflow: hidden;
}

.dm-request-btn:hover {
    background-color: #f8f9fa;
    border-color: #007bff;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.05);
}

.dm-request-btn i {
    font-size: 1.25rem;
    margin-right: 0.75rem;
    color: #007bff;
}

.dm-request-btn small {
    display: block;
    margin-top: 0.5rem;
    color: #6c757d;
    font-size: 0.875rem;
}

.dm-info {
    flex: 1;
}

.dm-info-text {
    display: block;
    color: #6c757d;
    margin-top: 0.5rem;
    font-size: 0.8125rem;
}

.dm-modal {
    background-color: rgba(0, 0, 0, 0.5);
}

.dm-modal-dialog {
    display: flex;
    align-items: center;
}

.large-modal {
    min-height: 90vh;
}

.large-modal .modal-body {
    height: calc(90vh - 140px);
    overflow-y: auto;
}

.modal-open {
    overflow: hidden !important;
    padding-right: 0 !important;
}

.file-link {
    text-decoration: none;
}

.file-link:hover {
    text-decoration: underline;
}

.text-black-25 {
    color: #9aa5af;
}

@media (max-width: 768px) {
    .dm-document-item {
        flex-wrap: nowrap;
    }

    .dm-document-name {
        margin-right: 1rem;
    }

    .dm-actions {
        flex-shrink: 0;
    }

    .dm-request-btn small {
        font-size: 0.75rem;
    }
}