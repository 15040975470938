/* ProcedureDetail.css */

.procedure-detail {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    color: #333;
    overflow-x: hidden;
}

.procedure-detail .row {
    margin-right: 0;
    margin-left: 0;
}

.procedure-detail-left,
.procedure-detail-right {
    padding-bottom: 0;
}

.procedure-detail-right {
    background-color: #f8f9fa;
    border-left: 1px solid #e0e0e0;
}

.procedure-detail .procedure-title {
    font-size: 2.5rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.procedure-detail .section {
    margin-bottom: 2rem;
}

.procedure-detail .section-title-left {
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 1rem;
    font-weight: 600;
    border-bottom: 0px;
    padding-bottom: 0.5rem;
}

.procedure-detail .result-text {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 4px;
    line-height: 1.6;
}

.procedure-detail .result-textarea {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    resize: vertical;
}

.procedure-detail .step-item:last-child {
    border-bottom: none;
}

.procedure-detail .badge {
    font-size: 0.85rem;
    padding: 0.5em 0.75em;
    border-radius: 20px;
    font-weight: 500;
}

.procedure-detail .btn {
    border-radius: 4px;
    font-weight: 500;
    transition: all 0.3s ease;
}

.procedure-detail .btn:hover {
    transform: translateY(-1px);
}

.procedure-detail .form-control {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0.375rem 0.75rem;
}

.procedure-detail .form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

@media (max-width: 767px) {
    .procedure-detail-right {
        border-left: none;
        border-top: 1px solid #e0e0e0;
    }

    .procedure-detail .procedure-title {
        font-size: 2rem;
    }

    .procedure-detail .section-title {
        font-size: 1.25rem;
    }
}