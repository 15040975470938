.list-group .list-group-item:last-child {
    border-bottom: 1px solid rgba(0,0,0,.125) !important;
}

.document-badges {
    max-height: 50px;
    overflow-y: auto;
}

.document-badges .badge {
    display: inline-block;
    white-space: nowrap;
    margin-bottom: 2px;
    border: 1px solid rgba(0,0,0,.125);
}