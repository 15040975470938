.document-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.document-item:hover {
    background: #f8f9fa;
}

.document-content {
    flex: 1;
    min-width: 0;
}

.document-name {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    color: #495057;
}

.document-name.clickable {
    cursor: pointer;
    color: #0d6efd;
}

.document-name i {
    margin-right: 0.5rem;
    color: #6c757d;
}

.document-actions {
    display: flex;
    gap: 0.5rem;
    margin-left: 1rem;
}

.document-actions span {
    display: inline-flex;
    align-items: center;
}

.dm-action-btn {
    cursor: pointer;
    padding: 0.25rem;
    color: #6c757d;
}

/* Styles spécifiques qui surchargent la couleur de base */
.dm-action-btn:hover {
    color: #0d6efd;
}

.dm-action-btn--send { 
    color: #0d6efd; 
}

.dm-action-btn--delete { 
    color: #dc3545; 
}

.document-type-name {
    font-size: 0.8rem;
    color: #9c9c9c;
    font-style: italic;
    font-weight: 400;
}