.procedure-header {
    margin-bottom: 1rem;
}

.procedure-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.assertion-badge {
    display: inline-flex;
    align-items: center;
    padding: 2px 8px;
    margin-right: 8px;
    border-radius: 3px;
    font-size: 0.85rem;
    font-weight: 500;
    background-color: #DEEBFF;
    color: #0747A6;
    border: none;
    transition: background-color 0.2s ease;
}

.assertion-badge:hover {
    background-color: #B3D4FF;
}

.assertion-badge i {
    font-size: 0.75rem;
    margin-right: 6px;
    color: #0052CC;
}

.assertions-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
    margin-bottom: 16px;
}

@media (max-width: 768px) {
    .procedure-title {
        font-size: 2rem;
    }
}