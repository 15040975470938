.irl-row {
  border-bottom: 1px solid var(--border-color);
  position: relative;
}

.irl-row:hover {
  background-color: var(--hover-color);
  z-index: 1;
}

.irl-cell {
  vertical-align: middle;
  padding: 12px 15px;
}

.irl-row a {
  color: var(--text-color);
  text-decoration: none;
}

.irl-row a:hover {
  text-decoration: none;
}

.irl-row .ir-title-link {
  text-decoration: none;
}

.irl-row .ir-title-link:hover {
  text-decoration: underline;
  color: var(--primary-color);
}

.btn-add-task {
  background-color: transparent;
  border: 1px solid var(--secondary-color);
  color: var(--text-color);
  font-weight: 500;
  padding: 8px 16px;
  font-size: 0.9rem;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-add-task:hover {
  background-color: var(--hover-color);
  border-color: var(--text-color);
  color: var(--text-color);
}

.btn-add-task:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
}

.btn-add-task i {
  margin-right: 8px;
}

.action-dropdown-wrapper {
  position: relative;
}

.action-icon {
  cursor: pointer;
}

.action-dropdown-menu {
  position: fixed;
  z-index: 1050;
  background-color: var(--white);
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  min-width: 200px;
  padding: 0.5rem 0;
  right: auto;
  left: auto;
}

.action-dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: var(--text-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.action-dropdown-menu .dropdown-item:hover, 
.action-dropdown-menu .dropdown-item:focus {
  color: var(--text-color);
  text-decoration: none;
  background-color: var(--hover-color);
}

.action-dropdown-menu .dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid var(--border-color);
}

.icon-spacing {
  margin-right: 0.5rem;
}

.action-dropdown .dropdown-menu.show {
  display: block !important;
}

.dropdown-toggle::after {
  display: none !important;
}

@media (max-width: 768px) {
  .btn-add-task {
    width: 100%;
    margin-top: 10px;
  }

  .action-dropdown-menu {
    left: 0 !important;
    right: auto !important;
  }
}