/* ProcedureList.css */

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.procedure-list-title {
    font-size: 1.8rem;
    margin-bottom: 0; 
}

.procedure-list-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.procedure-list-title {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
}

.global-progress-card {
    margin-bottom: 2rem;
}

.global-progress-card .card-title {
    font-size: 1.2rem;
    margin-bottom: 1.25rem;
}

.progress-text {
    font-size: 0.9rem;
    color: var(--text-color);
}

.filters-section {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
}

.filter-group:not(:last-child) {
    margin-bottom: 1.5rem;
}

.filter-label {
    font-size: 0.95rem;
    font-weight: 600;
    color: var(--bs-gray-700);
    margin-bottom: 0.75rem;
}

.filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.7rem;
}

.filter-button {
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
}

.procedures-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
}

.procedure-card .card-title {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.procedure-card .card-subtitle {
    font-size: 0.85rem;
    color: #7f8c8d;
    margin-bottom: 1rem;
}

.procedure-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.step-count {
    font-size: 0.85rem;
    color: var(--text-color);
}

.details-toggle-link {
    font-size: 0.9rem;
    color: var(--bs-primary);
    text-decoration: none;
    transition: color 0.2s ease-in-out;
}

.details-toggle-link:hover {
    color: var(--bs-primary-dark);
    text-decoration: underline;
}

/* Priority colors */
.text-orange {
    color: #fd7e14 !important;
}

.text-muted {
    color: #6c757d !important;
}

.text-success {
    color: #198754 !important;
}

.text-warning {
    color: #ffc107 !important;
}

.text-danger {
    color: #dc3545 !important;
}

#additional-info {
    background-color: #f8f9fa;
    border-radius: 0.25rem;
    padding: 1.5rem;
    margin-top: 1.5rem;
}

#additional-info h6 {
    font-size: 1rem;
    font-weight: 600;
    color: var(--bs-gray-700);
    margin-bottom: 1rem;
}

#additional-info p {
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
}

.risk-list {
    padding-left: 1.2rem;
    margin-bottom: 0;
}

.risk-list li {
    font-size: 0.95rem;
    margin-bottom: 0.5rem;
    color: var(--bs-gray-700);
}

.details-toggle-btn {
    background: none;
    border: none;
    padding: 0.5rem 1rem;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: var(--bs-primary);
    font-size: 0.9rem;
    transition: all 0.2s ease-in-out;
    border-radius: 4px;
}

.details-toggle-btn:hover {
    color: var(--bs-primary-dark);
    background-color: rgba(var(--bs-primary-rgb), 0.1);
}

@media (max-width: 768px) {
    .procedure-list-container {
        padding: 1rem;
    }

    .procedures-grid {
        grid-template-columns: 1fr;
    }

    .filters-section {
        padding: 1rem;
    }

    .filter-container {
        flex-direction: column;
    }

    .filter-button {
        width: 100%;
    }
}