/* Assistant.css */
.assistant-wrapper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 40px); /* Adjust for padding of main-wrapper */
    margin: -20px; /* Negate padding of main-wrapper */
  }
  
  .assistant-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    padding-bottom: 120px; /* Adjust based on your search bar height */
  }
  
  @media (max-width: 991.98px) {
    .assistant-wrapper {
      height: calc(100vh - 80px); /* Adjust for additional padding-top on small screens */
    }
  }