/* EditableField.css */
.editable-field {
    position: relative;
    width: 100%;
}

.editable-field__display-mode {
    cursor: pointer;
    position: relative;
    padding: 6px 8px;
    border-radius: 4px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
}

.editable-field__display-mode .edit-icon {
    opacity: 0;
    color: #6c757d;
    margin-left: 8px;
    font-size: 0.875rem;
    transition: opacity 0.2s ease;
}

.editable-field__display-mode:hover {
    background-color: #f8f9fa;
    border-color: #dee2e6;
}

.editable-field__display-mode:hover .edit-icon {
    opacity: 1;
}

/* Mode édition */
.editable-field__edit-mode {
    width: 100%;
}

.editable-field__edit-mode .input-group {
    display: flex;
    align-items: stretch;
}

.editable-field__edit-mode .form-control-sm,
.editable-field__edit-mode .form-select-sm {
    border-radius: 4px 0 0 4px;
    border-color: #dee2e6;
    padding: 0.375rem 0.75rem;
    height: calc(1.5em + 0.75rem + 2px);
}

.editable-field__edit-mode .input-group-append {
    display: flex;
    margin-left: -1px;
}

.editable-field__edit-mode .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(1.5em + 0.75rem + 2px);
    width: 2.5rem;
    padding: 0;
    border: 1px solid transparent;
}

.editable-field__edit-mode .btn-save {
    border-radius: 0;
    background-color: #28a745;
    border-color: #28a745;
}

.editable-field__edit-mode .btn-save:hover {
    background-color: #218838;
    border-color: #1e7e34;
}

.editable-field__edit-mode .btn-cancel {
    border-radius: 0 4px 4px 0;
    background-color: #f8f9fa;
    border-color: #dee2e6;
    color: #6c757d;
}

.editable-field__edit-mode .btn-cancel:hover {
    background-color: #e2e6ea;
    border-color: #dae0e5;
    color: #5a6268;
}

/* Ajustements pour les champs de type textarea */
.editable-field textarea {
    min-height: 100px;
    resize: vertical;
}

/* Style spécial pour les badges */
.editable-field .badge {
    transition: all 0.2s ease;
}

/* Ajustements spécifiques pour maintenir la mise en page */
.editable-field h2,
.editable-field p {
    margin: 0;
    flex-grow: 1;
}

/* Style pour le focus */
.editable-field__edit-mode .form-control-sm:focus,
.editable-field__edit-mode .form-select-sm:focus {
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    border-color: #80bdff;
}

/* Style responsive */
@media (max-width: 576px) {
    .editable-field__edit-mode .btn {
        width: 2rem;
    }
}