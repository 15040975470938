.btn-inline {
    display: inline-block;
    width: auto;
}

.btn-inline:hover i {
    margin-right: 5px;
    transition: margin-right 0.3s;
}
.overviewCard {
    border-color: #ececec !important;
    -webkit-box-shadow: 0px 2px 0px 0px #ECECEC; 
    box-shadow: 0px 2px 0px 0px #ECECEC;
}

.hiddenLogo h2 {
    text-align: center;
    font-size: 10rem !important;
}