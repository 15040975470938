/* variables.css */
:root {
    --font-primary: 'Inter', sans-serif;
    --font-secondary: 'Roboto', sans-serif;
    --font-title: 'Assistant', sans-serif;
    
    --color-soft-grey: #afafaf;
    --color-soft-pink: #f9cad5;
    --color-dark-pink: #944657;
    --color-dark-grey: #c4c4c4;
    --color-icon-light-grey: #929292;
    --color-icon-grey: #4b4b4b;
    
    --spacing-sm: 10px;
    --spacing-md: 15px;
  }