.bubble {
    padding: 20px;
    margin-bottom: 20px;
}

.bubbleLeft {
    background-color: #f7f7f7;
    color: #555555;
    margin-right:400px;
    border-radius: 20px 20px 20px 0px;
}

.bubbleRight {
    background-color: #944657;
    color: #ffffff;
    margin-left:400px;
    border-radius: 20px 20px 0 20px;
}

.sourceLink {
    margin-top:10px;
    font-weight: bold;
    color: #000000;
}

.sourceLink a {
    margin-left: 7px;;
    font-weight: normal;
    color: #000000;
    text-decoration: none;
    font-style: italic;
}

.sourceLink a:hover {
    color: #944657;
}

.bubble h1 {
    margin: 0;
    margin-bottom: 13px;
    font-size: 1.6em;
    font-weight: bold;
}
.bubble h2 {
    margin: 0;
    margin-bottom: 9px;
    font-size: 1.3em;
    font-weight: bold;
}
.bubble h3 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 1em;
    font-weight: bold;
    font-style: italic;
}

.link-button {
    background: none!important;
    border: none;
    padding: 0!important;
    font-family: arial, sans-serif;
    color: rgb(63, 63, 63);
    text-decoration: none;
    cursor: pointer;
 }
 .link-button:hover {
    color: rgb(0, 0, 0);
 }
 
 .bubble table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px; 
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    background-color: #ffffff; 
}

.bubble th {
    background-color: #e2e2e2; 
    color: #333333; 
    font-weight: bold; 
    padding: 10px; 
    text-align: left; 
    border-bottom: 2px solid #e0e0e0; 
}

.bubble td {
    padding: 10px; 
    border-bottom: 1px solid #e0e0e0; 
    color: #333333;
}

.bubble tr:hover {
    background-color: #f9f9f9;
}
