.layout-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.sidebar {
  width: 280px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1030;
  transition: transform 0.3s ease-in-out;
}

.main-wrapper {
  flex-grow: 1;
  margin-left: 280px;
  overflow-y: auto;
  padding: 20px;
  position: relative;
}

.main-content {
  height: 100%;
  position: relative;
  z-index: 1;
}

.custom-main-content {
  margin-top: 0 !important;
}

.custom-main-content > .container-fluid {
  margin-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.custom-main-content > .container-fluid > .col-md-12 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.toggle-sidebar {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1040;
}

@media (max-width: 991.98px) {
  .sidebar {
    transform: translateX(-100%);
  }

  .sidebar.show {
    transform: translateX(0);
  }

  .main-wrapper {
    margin-left: 0;
    padding-top: 60px;
  }

  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1029;
    display: none;
  }

  .sidebar-overlay.show {
    display: block;
  }
}

.action-icon {
  position: relative;
  z-index: 1;
}

body {
  position: relative;
}
.dropdown-menu {
  position: fixed !important;
  z-index: 1050 !important;
}