/* Team.css */
/* Styles pour la mise en page globale et la disposition des cartes */

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.team-container {
  width: 100%;
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.team-cards-container {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.team-card-wrapper {
  width: 25%;
  padding: 10px;
  display: flex;
}

@media (max-width: 1200px) {
  .team-card-wrapper {
    width: 33.33%;
  }
}

@media (max-width: 992px) {
  .team-card-wrapper {
    width: 50%;
  }
}

@media (max-width: 576px) {
  .team-card-wrapper {
    width: 100%;
  }
}

/* Styles pour le spinner de chargement */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

/* Styles pour les messages d'alerte */
.alert {
  margin-top: 1rem;
}