.activity-list {
    font-size: 14px;
}

.activity-item {
    padding: 8px;
    border-bottom: 1px solid #f1f1f1;
}

.activity-date {
    font-size: 12px;
}
.activity-item a {
    color: #000;
    text-decoration: none;
}   
.activity-item a:hover {
    text-decoration: underline;
}
.no-activities-message {
    padding: 15px;
    text-align: center;
    color: #888;
    font-size: 16px;
}