.admin-layout {
  min-height: 100vh;
  background-color: #f8f9fa;
  padding-top: 56px; /* Adjust this value based on your navbar height */
}

.content-wrapper {
  padding-top: 1rem;
}

.navbar {
  box-shadow: 0 2px 4px rgba(0,0,0,.1);
}

.navbar-brand {
  font-weight: 600;
}

/* Add these new styles */
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}