.documents-dropdown {
    display: inline-flex;
    align-items: center;
}

.document-item {
    transition: background-color 0.2s;
}

.document-item:hover:not(:disabled) {
    background-color: #f8f9fa;
    cursor: pointer;
}

.document-name {
    font-weight: 500;
    margin-bottom: 2px;
}
