/* Styles spécifiques à Workspace */

.workspace-actions {
  display: flex;
  gap: 10px;
}

.workspace-actions .btn {
  min-width: 120px;
}

.tree-container {
  margin-top: 20px;
}

.tree-item-row, .control-point-row {
  border-bottom: 1px solid var(--border-color);
}

.tree-item-row:last-child, .control-point-row:last-child {
  border-bottom: none;
}

.tree-item-cell, .control-point-cell {
  vertical-align: middle;
  padding: 12px 15px;
}

.tree-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tree-item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tree-item-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
}

.toggle-icon {
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.toggle-icon i {
  font-size: 16px;
  color: var(--secondary-color);
}

.item-name {
  font-weight: 500;
  color: var(--text-color);
}

.category-row .tree-item-cell {
  background-color: var(--category-bg);
}

.category-row .item-name {
  font-weight: 600;
  color: var(--category-text);
}

.control-point {
  cursor: pointer;
  transition: color 0.3s ease;
}

.control-point:hover {
  color: var(--primary-color);
}

.item-progress {
  display: flex;
  align-items: center;
}

.percentage-container {
  width: 120px;
  height: 6px;
  background-color: var(--border-color);
  border-radius: 3px;
  overflow: hidden;
}

.percentage-bar {
  height: 100%;
  background-color: var(--primary-color);
  transition: width 0.3s ease-in-out;
}

.percentage-text {
  margin-left: 10px;
  font-size: 0.85rem;
  color: var(--secondary-color);
}

.status-container {
  display: flex;
  justify-content: flex-end;
  width: 120px;
}

@media (max-width: 768px) {
  .workspace-actions {
    margin-top: 15px;
    width: 100%;
    justify-content: flex-end;
  }
  
  .tree-item-container {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .item-progress {
    margin-top: 10px;
  }
  
  .status-container {
    margin-top: 10px;
    width: 100%;
  }
}