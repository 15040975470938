/* Styles généraux pour la modal */
.edit-modal .modal-dialog {
    max-width: 80% !important;
    width: 80% !important;
    margin: 1.75rem auto !important;
}

.edit-modal .modal-content {
    border-radius: 0 !important;
    border: none !important;
    max-height: 90vh !important;
    overflow-y: auto !important;
}

/* Styles pour le mode plein écran */
.edit-modal.modal-fullscreen .modal-dialog {
    max-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}

.edit-modal.modal-fullscreen .modal-content {
    height: 100% !important;
    max-height: 100% !important;
    border: 0 !important;
    border-radius: 0 !important;
}

/* En-tête de la modal */
.edit-modal .modal-header {
    background-color: #f4f5f7 !important;
    border-bottom: 1px solid #dfe1e6 !important;
    padding: 1rem 1.5rem !important;
}

.edit-modal .modal-title {
    font-size: 1.8rem !important;
    color: #000 !important;
    font-family: 'Assistant', sans-serif !important;
    font-weight: 500 !important;
}

/* Corps de la modal */
.edit-modal .modal-body {
    background-color: #ffffff !important;
    padding: 2rem 1.5rem !important;
    font-size: 1rem !important;
    color: #4e4e4e !important;
    font-family: 'Inter', sans-serif !important;
    overflow-x: hidden !important;
}

/* Styles des formulaires */
.edit-modal .form-label {
    font-weight: 500 !important;
    color: #5e6c84 !important;
}

.edit-modal .form-control-lg {
    border: 2px solid #dfe1e6 !important;
    border-radius: 3px !important;
    padding: 0.5rem 0.75rem !important;
    font-size: 1rem !important;
}

.edit-modal .form-control-lg:focus {
    border-color: #4c9aff !important;
    box-shadow: 0 0 0 2px rgba(76, 154, 255, 0.3) !important;
}

/* Styles des boutons */
.edit-modal .btn-primary {
    background-color: #0052cc !important;
    border-color: #0052cc !important;
}

.edit-modal .btn-primary:hover {
    background-color: #0065ff !important;
    border-color: #0065ff !important;
}

/* Styles de l'aperçu utilisateur */
.preview {
    background-color: #f4f5f7 !important;
    border-radius: 3px !important;
    padding: 1.5rem !important;
    text-align: center !important;
}

.preview h4 {
    margin-bottom: 1rem !important;
    color: #5e6c84 !important;
}

.form-control-lg h5 {
    margin-top: 1rem !important;
    font-weight: 500 !important;
}

.preview p {
    color: #5e6c84 !important;
    margin-bottom: 0.5rem !important;
}

/* Styles pour les écrans plus petits */
@media (max-width: 992px) {
    .edit-modal .modal-dialog {
        max-width: 95% !important;
        width: 95% !important;
    }

    .preview {
        margin-top: 2rem !important;
    }
}