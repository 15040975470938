.WSCardContentActivities {
    background-color: #f7f7f7 !important;
    height: 100%;
    min-height: calc(100vh - 76px);
}

.ActivitiesMenu .nav-pills .nav-link {
    background-color: #ebebeb !important;
    margin-right: 10px !important;
    padding: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    color: #333 !important;
}
.ActivitiesMenu .nav-pills .nav-link:hover {
    background-color: #e7e7e7 !important;
    color: #000 !important;
}
.ActivitiesMenu .nav-pills .nav-link.active {
    font-weight: bolder !important;
}

.editing-container {
    margin-top: 20px;
}

.editable-textarea {
    width: 100%;
    height: 150px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 10px;
}

.editable-textinput {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 10px;
}

.edit-buttons {
    display: flex;
    justify-content: flex-start;
}

.edit-buttons .btn {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    padding: 0px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.view-content {
    padding: 5px;;
    cursor: pointer;
    transition: background-color 0.2s;
}

.view-content:hover {
    background-color: #f9f9f9;
}

.view-content h1 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 5px;
    color: #000;
}

.view-content table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px; 
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    background-color: #ffffff; 
}

.view-content th {
    background-color: #e2e2e2; 
    color: #333333; 
    font-weight: bold; 
    padding: 10px; 
    text-align: left; 
    border-bottom: 2px solid #e0e0e0; 
}

.view-content td {
    padding: 10px; 
    border-bottom: 1px solid #e0e0e0; 
    color: #333333;
}

.view-content tr:hover {
    background-color: #f9f9f9;
}