.sidebar {
    background-color: #f7f7f7;
    color: rgb(0, 0, 0);
    font-family: 'Inter', sans-serif;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 280px;
}

.sidebar-content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
}

.sidebar .logo-container {
    padding: 20px 10px 10px;
    margin-bottom: 1rem;
}

.sidebar .logo-container img {
    max-width: 100%;
    height: auto;
}

.sidebar .nav-link, 
.sidebar .accordion-button,
.sidebar .user-menu .nav-link,
.offcanvas .nav-link, 
.offcanvas .accordion-button,
.offcanvas .user-menu .nav-link {
    color: rgb(0, 0, 0);
    margin-bottom: 5px;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
}

.sidebar .nav-link:hover, 
.sidebar .nav-link:focus, 
.sidebar .nav-link.active,
.sidebar .accordion-button:hover,
.sidebar .accordion-button:focus,
.sidebar .user-menu .nav-link:hover,
.sidebar .user-menu .nav-link:focus,
.offcanvas .nav-link:hover, 
.offcanvas .nav-link:focus, 
.offcanvas .nav-link.active,
.offcanvas .accordion-button:hover,
.offcanvas .accordion-button:focus,
.offcanvas .user-menu .nav-link:hover,
.offcanvas .user-menu .nav-link:focus {
    background-color: #ebebeb;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    color: rgb(0, 0, 0);
}

.sidebar .nav-item {
    margin-bottom: 5px;
}

.sidebar .accordion-item,
.offcanvas .accordion-item {
    background-color: transparent;
    border: none;
}

.sidebar .accordion-button,
.offcanvas .accordion-button {
    font-weight: 500;
    padding: 0.5rem 1rem;
}

.sidebar .accordion-button:not(.collapsed),
.offcanvas .accordion-button:not(.collapsed) {
    background-color: #ebebeb;
    color: rgb(0, 0, 0);
}

.sidebar .accordion-button:focus,
.offcanvas .accordion-button:focus {
    box-shadow: none;
    border-color: transparent;
}

.sidebar .accordion-body,
.offcanvas .accordion-body {
    padding: 0;
}

.sidebar .accordion-body .nav-link,
.offcanvas .accordion-body .nav-link {
    padding-left: 2rem;
}

.sidebar .nav-link i, 
.sidebar .accordion-button i,
.sidebar .user-menu .nav-link i,
.offcanvas .nav-link i, 
.offcanvas .accordion-button i,
.offcanvas .user-menu .nav-link i {
    margin-right: 0.5rem;
    font-size: 1.1rem;
}

.sidebar .spinner-border,
.offcanvas .spinner-border {
    width: 2rem;
    height: 2rem;
}

.sidebar-bottom {
    margin-top: auto;
    padding: 1rem 10px;
    border-top: 1px solid #dee2e6;
    background-color: #f7f7f7;
}

.sidebar-content::-webkit-scrollbar {
    width: 6px;
}

.sidebar-content::-webkit-scrollbar-track {
    background: #f7f7f7;
}

.sidebar-content::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
}

.dataroom-name {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.accordion-button::after {
    margin-left: 0.5rem;
}

@media (max-width: 991.98px) {
    .sidebar {
        display: none;
    }
    
    .offcanvas {
        width: 280px !important;
    }
}

@media (min-width: 992px) {
    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
    }
}

.language-menu {
    position: relative;
}

.language-submenu {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background-color: #f7f7f7;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    margin-top: 2px;
    z-index: 1000;
}

.language-submenu .nav-link {
    padding: 0.5rem 1rem;
    margin: 0;
    border-radius: 0;
}

.language-submenu .nav-link:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.language-submenu .nav-link:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.language-submenu .nav-link.active {
    background-color: #e9ecef;
    font-weight: 500;
}

.language-menu .nav-link:hover,
.language-submenu .nav-link:hover {
    background-color: #ebebeb;
}