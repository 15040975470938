/* Styles spécifiques aux documents */

.breadcrumbDoc {
  padding: 10px 0;
  background-color: transparent;
}

.breadcrumbDoc .breadcrumb-item + .breadcrumb-item::before {
  color: var(--secondary-color) !important;
}

.breadcrumbDoc a {
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.breadcrumbDoc a:hover {
  color: var(--primary-color);
}

/* Remplacer .documents-table par .custom-table */
.custom-table {
  overflow: visible !important;
}

.tableRow {
  position: relative;
}

.tableRow:hover {
  z-index: 2;
}

.tableCellName {
  width: 60%;
}

/* Suppression de la flèche du dropdown */
.dropdown-toggle::after {
  display: none !important;
}