.viewer-container {
    height: calc(100vh - 90px);
    width: 100%;
}

#msdoc-renderer {
  height: 100% !important;
  overflow: hidden !important;
}

#react-doc-viewer {
  height: 100% !important;
  overflow: auto !important;
}

[data-testid="pdf-renderer"] {
  height: 100% !important;
  overflow: auto !important;
}

#pdf-controls {
  z-index: 5;
}

#proxy-renderer {
  display: flex !important;
  flex: 1 !important;
}