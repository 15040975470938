@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
  --primary-color: #0056b3;
  --secondary-color: #6c757d;
  --background-color: #f7f7f7;
  --border-color: #dee2e6;
  --text-color: #000000;
  --hover-color: #ebebeb;
  --white: #ffffff;
}

body {
  font-family: 'Inter', sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
}

/* Layout structure */
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 0 15px 0;
  border-bottom: 1px solid var(--border-color);
}

.page-title {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  color: var(--text-color);
}

.page-actions {
  display: flex;
  gap: 10px;
}

.page-content {
  flex: 1;
  overflow-y: auto;
}

/* Table styles */
.table-container {
  background-color: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  overflow: hidden;
  margin-top: 20px;
}

.custom-table {
  width: 100%;
  margin-bottom: 0 !important;
  border-bottom: 2px solid #333;
}

.custom-table th {
  background-color: var(--background-color);
  border-bottom: 2px solid var(--border-color);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.85rem;
  color: var(--secondary-color);
  padding: 15px;
}

.custom-table td {
  vertical-align: middle;
  padding: 12px 15px;
  border-bottom: 1px solid var(--border-color);
}

.custom-table tr:last-child td {
  border-bottom: none;
}

.custom-table tr:hover {
  background-color: var(--hover-color);
}

/* Loading and error messages */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.error-message {
  color: #dc3545;
  text-align: center;
  padding: 20px;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid #dc3545;
  margin-top: 20px;
}

.empty-message {
  padding: 30px;
  font-style: italic;
  color: var(--secondary-color);
  text-align: center;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--border-color);
}

/* Status badges */
.status-badge {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.85rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-success {
  background-color: #d4edda;
  color: #155724;
}

.status-danger {
  background-color: #f8d7da;
  color: #721c24;
}

.status-secondary {
  background-color: #e2e3e5;
  color: #383d41;
}

.status-primary {
  background-color: #cce5ff;
  color: #004085;
}

/* Responsive design */
@media (max-width: 768px) {
  .page-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .page-actions {
    margin-top: 10px;
    width: 100%;
    justify-content: flex-start;
  }
}