.delete-options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.delete-option {
    display: flex;
    align-items: start;
    padding: 1.25rem;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.delete-option:hover {
    border-color: #6c757d;
    background-color: #f8f9fa;
}

.delete-option--danger:hover {
    border-color: #dc3545;
    background-color: #fff8f8;
}

.delete-option-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: #f8f9fa;
    margin-right: 1rem;
    font-size: 1.25rem;
}

.delete-option--danger .delete-option-icon {
    color: #dc3545;
}

.delete-option-content {
    flex: 1;
}

.delete-option-content h6 {
    margin: 0 0 0.5rem 0;
    font-weight: 500;
    color: #212529;
}

.delete-option-content p {
    margin: 0;
    font-size: 0.875rem;
    color: #6c757d;
}

.delete-option--danger .delete-option-content h6 {
    color: #dc3545;
}