.chat-input-area {
    flex: 0 0 auto;
    padding: 1.5rem;
    border-top: 1px solid #dee2e6;
    background-color: white;
}

.message-form {
    margin-top: 1rem;
}

.input-container {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
    position: relative;
}

.message-textarea {
    flex: 1;
    resize: none;
    border: 1px solid #dee2e6;
    border-radius: 0.5rem;
    padding: 0.75rem;
    padding-right: 4rem;
    font-size: 0.95rem;
    line-height: 1.5;
    transition: border-color 0.15s ease-in-out;
    min-height: 60px;
}

.message-textarea:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.message-textarea:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
}

.send-button {
    position: absolute;
    right: 0.75rem;
    bottom: 0.75rem;
    background-color: #0d6efd;
    color: white;
    border: none;
    border-radius: 0.375rem;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.send-button:hover {
    background-color: #0b5ed7;
}

.send-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
    opacity: 0.65;
}

/* Quick Prompts Styling */
.quick-prompts-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.quick-prompt-button {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border: 1px solid #dee2e6;
    border-radius: 20px;
    background-color: #f8f9fa;
    color: #495057;
    transition: all 0.2s ease;
    cursor: pointer;
    white-space: nowrap;
}

.quick-prompt-button:hover {
    background-color: #e9ecef;
    border-color: #ced4da;
}

.quick-prompt-button:active {
    background-color: #dee2e6;
}

@media (max-width: 768px) {
    .chat-input-area {
        padding: 1rem;
    }
    
    .input-container {
        gap: 0.5rem;
    }
    
    .quick-prompts-container {
        margin-bottom: 0.75rem;
    }
}