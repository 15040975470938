.initChat {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-bottom: 120px;
}

.initChat h2 {
    font-size: 7rem;
    color: #f7f7f7;
    font-family: 'Assistant', sans-serif;    
    text-shadow: 1px 1px 1px #ececec, -1px -1px 1px #ffffff; 
    padding: 10px;  
}