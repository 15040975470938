.procedure-details-left {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    padding: 2rem;
}

.procedure-content {
    margin-bottom: 2rem;
}

.section-title {
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 1rem;
    font-weight: 600;
}

.section-title-left {
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 1rem;
    font-weight: 600;
}

.description-section,
.steps-section {
    margin-bottom: 2rem;
}

/* Edit controls - utilisé partout */
.edit-icon {
    font-size: 0.8rem !important;
    color: #333;
    opacity: 0.7;
}

.edit-icon:hover {
    opacity: 1;
}

.edit-button {
    padding: 2px !important;
    margin-left: 8px !important;
}

.edit-container {
    margin-bottom: 1rem;
}

.edit-actions {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

/* Responsive */
@media (max-width: 768px) {
    .procedure-details-left {
        padding: 1rem;
    }

    .section-title {
        font-size: 1.25rem;
    }
}

.multiline-text {
    white-space: pre-line;
}

/* Findings styles */
.findings-container {
    background-color: #fff;
}

.findings-content {
    margin-top: 2rem;
}

.findings-content > section {
    background-color: #fff;
    border: 1px solid #e9ecef;
    border-bottom: 2px solid #e9ecef;
    border-radius: 6px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
}

.findings-content > section:last-child {
    margin-bottom: 0;
}

/* Status styles */
.procedure-status {
    display: flex;
    align-items: center;
}

.status-badge {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.9rem;
}

.status-badge.status-pending {
    background-color: #e9ecef;
    color: #495057;
}

.status-badge.status-in-progress {
    background-color: #cff4fc;
    color: #055160;
}

.status-badge.status-missing-documents {
    background-color: #fff3cd;
    color: #856404;
}

.status-badge.status-completed {
    background-color: #d1e7dd;
    color: #0f5132;
}