/* Styles spécifiques aux activités */

.user-filter {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.filter-label {
    margin-right: 10px;
    font-weight: 500;
    color: var(--secondary-color);
}

.user-badge {
    margin: 5px;
    padding: 8px 12px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.user-badge:hover {
    opacity: 0.8;
}

.section-title {
    background-color: var(--background-color);
    font-weight: 600;
    color: var(--secondary-color);
}

.section-title td {
    padding: 10px 15px;
}

/* ActivityCard styles */
.activity-row {
    transition: background-color 0.3s ease;
}

.activity-row:hover {
    background-color: var(--hover-color);
}

.activity-row td {
    vertical-align: middle;
    padding: 12px 15px;
    border-bottom: 1px solid var(--border-color);
}

.activity-event {
    display: flex;
    align-items: center;
}

.activity-event .activityIcon {
    font-size: 1.2rem;
    color: var(--secondary-color);
    margin-right: 10px;
}

.activity-user a {
    color: var(--text-color);
    text-decoration: none;
    font-weight: 500;
}

.activity-user a:hover {
    color: var(--primary-color);
    text-decoration: underline;
}

.activity-description {
    color: var(--text-color);
}

.activity-description a {
    color: var(--text-color);
    text-decoration: underline;
}

.activity-description a:hover {
    color: var(--primary-color);
}

.activity-date {
    font-size: 0.9rem;
    color: var(--secondary-color);
}

@media (max-width: 768px) {
    .custom-table {
        font-size: 0.9rem;
    }

    .activity-event .activityIcon {
        font-size: 1rem;
    }

    .user-filter {
        flex-direction: column;
        align-items: flex-start;
    }

    .filter-label {
        margin-bottom: 10px;
    }
}