.custom-modal-size {
    max-width: 66% !important; 
}

.dropzone {
    border: 2px dashed #9e9c9e; 
    border-radius: 5px; 
    padding: 20px;
    text-align: center; 
    background-color: #f8f9fa;
    color: #6c757d;
    transition: background-color 0.3s;
}

.dropzone:hover {
    background-color: #eaedf0; 
    border: 2px dashed #797979; 
}

.dropzone:focus {
    outline: none; 
}

.file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px; /* Ajustez selon besoin pour l'espacement entre les items */
}

.file-info {
    display: flex;
    align-items: center;
    flex-grow: 1; /* Assure que le conteneur prend toute la largeur disponible */
}

.file-name {
    flex-grow: 1; /* Permet au nom du fichier de prendre l'espace disponible */
    white-space: nowrap; /* Garde le nom du fichier sur une seule ligne */
    overflow: hidden; /* Cache le texte qui dépasse du conteneur */
    text-overflow: ellipsis; /* Ajoute des points de suspension si le texte est trop long */
}

.delete-btn {
    margin-left: 10px;
    color: #dc3545;
    cursor: pointer;
    position: static !important; /* Ceci annulera tout positionnement absolu */
    bottom: auto !important; /* Ceci annulera le bottom: 10px */
    display: flex !important; /* Assure un alignement vertical correct */
    align-items: center;
}

.file-status {
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Alignement à droite pour la ProgressBar et les badges */
    min-width: 160px; 
    margin-right: 20px;
}
