.pin-container {
    position: relative;
    display: inline-flex; 
    align-items: center;
}

.custom-dropdown {
    position: relative;
}

.link-button i.bi {
    color: black;
    font-size: 20px; /* Taille de l'icône */
}

.custom-dropdown-menu {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 250px;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    overflow-y: auto; /* Ajout d'une barre de défilement si nécessaire */
    max-height: 300px; /* Hauteur maximale */
}

.custom-dropdown-item {
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    align-items: center; /* Centrage vertical du contenu */
}

.custom-dropdown-item:hover {
    background-color: #eee; /* Couleur de fond au survol */
}

.icon-check {
    color: #479e44; /* Couleur de l'icône de vérification */
    margin-right: 5px; /* Espace entre l'icône et le texte */
}

/* Styles pour les niveaux de dossiers */
.custom-dropdown-item.level-0 {
    font-weight: bold; /* Dossiers de niveau supérieur plus gras */
}

.custom-dropdown-item.level-1 {
    padding-left: 25px; /* Indentation pour le niveau 1 */
}

.custom-dropdown-item.level-2 {
    padding-left: 40px; /* Indentation pour le niveau 2 */
}

/* Ajoutez plus de styles de niveau si nécessaire */

/* Styles supplémentaires pour les icônes */
.bi-plus, .bi-check {
    margin-right: 8px; /* Espacement entre l'icône et le texte */
}

/* Afficher une barre de séparation entre les catégories */
.workspace-category {
    border-bottom: 1px solid #ccc;
    padding: 10px 15px;
    background-color: #f6f6f6;
}
