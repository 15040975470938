.newDataRoomButton {
    font-size: 6em !important;
    transition: color 0.3s ease !important;
    color: #000000 !important;
}

.newDataRoomButton:hover {
    color: #f9cad5 !important;
}

.dataRoomCard {
    height: 100% !important; 
    display: flex !important;
    flex-direction: column !important;
}
.dataRoomCard .card-body {
    flex: 1 !important; 
}

.dataRoomCardFooter {
    border:0px !important;
    background-color: transparent !important;
}

.square-img {
    width: 100%;  
    height: 0; 
    padding-bottom: 100%; 
    position: relative;
}

.square-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;  
}