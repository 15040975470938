/* Search.css */
.Search {
    background-color: #f9cad5;
    border-top: 1px solid #fff;
    position: fixed;
    left: 280px; /* Width of sidebar */
    right: 0;
    bottom: 0;
    z-index: 500;
    width: calc(100% - 280px); /* Adjust based on sidebar width */
  }
  
  .search-input, .Search #folderSelector, .reset-btn {
    background-color: rgba(255, 255, 255, 0.4) !important;
    border: 1px solid #ffeff2 !important;
    color: #6b2836 !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 1.1em !important;
  }
  
  .Search #folderSelector .form-control {
    overflow: hidden;
    text-align: left;
    background-color: transparent !important;
    border: none !important;
    color: #6b2836 !important;
    height: 100%;
  }
  
  .search-input:focus, .search-input:hover {
    outline: none;
    background-color: rgba(255, 255, 255, 0.4) !important;
    border: 1px solid #fff !important;
    color: #6b2836 !important;
    box-shadow: none !important;
  }
  
  .search-input::placeholder {
    color: #9b5967 !important;
  }
  
  .suggestions-container {
    position: absolute;
    bottom: 100%; 
    width: calc(100% - 20px);
    border: 1px solid #e6e6e6;
    border-bottom: none;
    background-color: #fff;
    z-index: 10;
    max-height: 135px;
    overflow-y: auto;
    border-radius: 5px 5px 0 0;
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #e6e6e6;
  }
  
  .suggestion-item:hover {
    background-color: #f5f5f5;
  }
  
  .reset-btn:hover, .reset-btn:focus {
    background-color: rgba(255, 255, 255, 0.2) !important;
    color: #6b2836 !important;
    outline: none;
    box-shadow: none !important;
  }
  
  .reset-container {
    background-color: rgba(255, 255, 255, 0.4) !important;
    border: 1px solid #fff !important;
    border-right: none !important;
  }
  
  @media (max-width: 991.98px) {
    .Search {
      left: 0;
      width: 100%;
    }
  }