.admin_sidebar {
    position: fixed;
    top: 56px; /* Height of the navbar */
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    background-color: #f8f9fa;
  }
  
  .admin_sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .admin_sidebar .nav-link {
    font-weight: 500;
    color: #333;
  }
  
  .admin_sidebar .nav-link.active {
    color: #007bff;
  }
  
  .admin_main-content {
    margin-left: 16.66667%; /* Adjust based on the width of your sidebar */
    padding: 20px;
  }
  
  .admin_action-button {
    white-space: nowrap;
  }
  
  .admin_table-nowrap {
    white-space: nowrap;
  }
  
  .admin_big-number {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0;
  }
  
  .admin_card {
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .admin_card:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  
  @media (max-width: 767.98px) {
    .admin_sidebar {
      position: static;
      height: auto;
      padding-top: 0;
    }
    
    .admin_main-content {
      margin-left: 0;
    }
  }