.assistant-message {
    background-color: white;
    border: 1px solid #e9ecef;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
    animation: fadeIn 0.3s ease-in;
    max-width: 75%;
}

.assistant-message ul {
    margin: 0.5rem 0;
    padding-left: 1.5rem;
}

.assistant-message li {
    margin: 0.25rem 0;
}