.source-container {
    display: flex;
    flex-direction: column;
    padding: 0px;
}

.source-title {
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
}

.source-button {
    background-color: transparent;
    border: none;
    padding: 2px 0;
    margin-bottom: 3px;
    margin-left: 20px; /* Espace à gauche */
    transition: color 0.3s;
    display: flex;
    align-items: center;
}

.source-button:hover {
    color: inherit; /* Pour garder la même couleur lors du survol */
}

.source-icon {
    font-size: 20px;
    margin-right: 5px;
}

.source-text {
    font-size: 14px;
    color: #555;
}
