:root {
    --border-color: #e0e0e0;
    --border-bottom-color: #ced4da;
    --background-color: #ffffff;
    --text-color: #333333;
    --secondary-color: #6c757d;
    --hover-color: #f8f9fa;
}

.wscard {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-bottom: 2px solid var(--border-bottom-color);
    border-radius: 5px;
    transition: background-color 0.3s ease;
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.wscard:hover {
    background-color: var(--hover-color);
}

.wscard-title {
    font-size: 18px;
    font-weight: 600;
    color: var(--text-color);
    padding: 16px;
    margin: 0;
    border-bottom: 1px solid var(--border-color);
}

.wscard-content {
    flex-grow: 1;
    padding: 16px;
    font-size: 14px;
    color: var(--secondary-color);
    overflow: hidden;
}

.wscard-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    color: var(--secondary-color);
    font-size: 12px;
    border-top: 1px solid var(--border-color);
}

.wscard-footer i {
    font-size: 14px;
}