.audit-overview-card {
    background: var(--background-color-light, #fff);
    margin-bottom: 1.5rem;
}

.audit-overview-title {
    color: black;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.audit-overview-info {
    padding: 0.5rem 0;
}

.audit-overview-info p {
    margin-bottom: 0.75rem;
    color: var(--text-color, #2c3e50);
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.audit-overview-info .icon {
    color: var(--primary-color);
    opacity: 0.7;
}

/* Section Cards */
.audit-risk-assessment,
.audit-financial-highlights,
.audit-thresholds,
.workflow-progress,
.additional-info {
    padding: 1.25rem;
    border-radius: 6px;
    margin-bottom: 1.5rem;
    background-color: var(--background-color, #f8f9fa);
}

/* Section Titles */
.audit-details .card-title {
    color: var(--text-color-dark, #172b4d);
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1.5rem 0 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--border-color, #e9ecef);
}

/* Toggle Button */
.details-toggle-btn {
    background: none;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.2s ease;
}

.details-toggle-btn:hover {
    background-color: var(--background-color, #f8f9fa);
    color: var(--primary-color);
}

.details-toggle-btn:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.2);
}

.details-toggle-btn i {
    font-size: 0.8rem;
    transition: transform 0.2s ease;
}

/* Details Section */
.audit-details {
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.audit-details.show {
    display: block;
    opacity: 1;
}

/* Value Display with Separator */
.audit-details p {
    display: flex;
    align-items: center;
    padding: 0.75rem 0;
    margin: 0;
    border-bottom: 1px solid var(--border-color, #e9ecef);
    position: relative;
}

.audit-details p:last-child {
    border-bottom: none;
}

.audit-details p strong {
    color: var(--text-color-dark, #172b4d);
    font-weight: 500;
    flex: 0 0 40%;
    position: relative;
    padding-right: 2rem;
}

.audit-details p strong::after {
    content: "";
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 70%;
    background-color: var(--border-color, #e9ecef);
}

.audit-details p span {
    flex: 1;
    padding-left: 1rem;
    color: #555658;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .audit-overview-info {
        padding: 0;
    }
    
    .audit-risk-assessment,
    .audit-financial-highlights,
    .audit-thresholds,
    .workflow-progress,
    .additional-info {
        padding: 1rem;
    }

    .audit-details p {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        padding: 1rem 0;
    }

    .audit-details p strong {
        padding-right: 0;
        flex: none;
        width: 100%;
    }

    .audit-details p strong::after {
        display: none;
    }

    .audit-details p span {
        padding-left: 0;
    }
}