:root {
  --button-bg: #ffffff;
  --button-text: #495057;
  --button-border: #ced4da;
  --button-hover-bg: #f8f9fa;
  --button-hover-border: #adb5bd;
  --button-focus-shadow: rgba(206, 212, 218, 0.5);
}

.action-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.btn-action {
  background-color: var(--button-bg);
  color: var(--button-text);
  border: 1px solid var(--button-border);
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
}

.btn-action:hover {
  background-color: var(--button-hover-bg);
  border-color: var(--button-hover-border);
}

.btn-action:focus {
  box-shadow: 0 0 0 0.2rem var(--button-focus-shadow);
}

.btn-action i {
  margin-right: 8px;
  font-size: 16px;
}

.btn-add-folder,
.btn-add-file {
  min-width: 120px;
}

@media (max-width: 768px) {
  .action-buttons {
    flex-direction: column;
    width: 100%;
  }

  .btn-action {
    width: 100%;
    margin-bottom: 10px;
  }
}