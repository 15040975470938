/* Base Container */
.thread-sidebar {
    display: flex;
    flex-direction: column;
    width: 320px;
    background: #fff;
    border-right: 1px solid #DFE1E6;
    height: 100%;
}

/* Header Section */
.thread-sidebar-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* New Thread Button */
.new-thread-btn {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    padding: 6px 12px;
    font-weight: 500;
}

/* Thread List Container */
.thread-list {
    flex: 1;
    overflow-y: auto;
}

/* Thread Items */
.thread-item {
    padding: 10px 16px;
    border: none !important;
    transition: background-color 0.1s ease;
    cursor: pointer;
}

.thread-item:hover {
    background-color: #F4F5F7;
}

.thread-item.active {
    background-color: #DEEBFF !important;
}

/* Thread Content Structure */
.thread-item-content {
    display: flex;
    justify-content: space-between;
    gap: 12px;
}

.thread-main-content {
    flex: 1;
    min-width: 0;
}

/* Thread Title Section */
.thread-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.thread-title {
    font-weight: 500;
    color: #172B4D;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875rem;
    margin-right: 8px;
    flex: 1;
}

.thread-actions {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
}

.thread-time {
    color: #6B778C;
    font-size: 0.75rem;
    white-space: nowrap;
}

/* Delete Button */
.delete-button {
    background: none;
    border: none;
    padding: 4px;
    color: #6B778C;
    opacity: 0;
    transition: all 0.1s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.thread-item:hover .delete-button {
    opacity: 1;
}

.delete-button:hover {
    color: #DE350B;
}

/* Scrollbar Styling */
.thread-list::-webkit-scrollbar {
    width: 8px;
}

.thread-list::-webkit-scrollbar-track {
    background: transparent;
}

.thread-list::-webkit-scrollbar-thumb {
    background-color: #A5ADBA;
    border-radius: 4px;
}

.thread-list::-webkit-scrollbar-thumb:hover {
    background-color: #8993A4;
}

/* Empty State */
.thread-list .text-muted {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 16px;
    color: #6B778C;
    font-size: 0.875rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .thread-sidebar {
        width: 280px;
    }
}