.procedure-card {
    transition: box-shadow 0.3s ease-in-out;
    border-top-width: 4px !important;
    border-top-style: solid;
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
}

.procedure-card:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transform: translateY(-2px);
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.procedure-card .card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.procedure-card .card-title {
    font-size: 1.1rem;
    margin-bottom: 0.75rem;
}

.procedure-card-status {
    width: 100% !important;
    justify-content: flex-start !important;
    padding-left: 0 !important;
}

.status-badge {
    font-size: 0.8rem;
    padding: 0.4em 0.6em;
    font-weight: 500;
    color: white;
    border-radius: 0.25rem;
    display: inline-block;
}

.card-subtitle {
    font-size: 0.85rem;
    color: #6c757d;
    margin-bottom: 0.75rem;
}

.procedure-footer {
    margin-top: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.priority-badge {
    font-size: 0.8rem;
    padding: 0.4em 0.6em;
    font-weight: 500;
}