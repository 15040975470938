.quick-prompts-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.quick-prompt-button {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border: 1px solid #dee2e6;
    border-radius: 20px;
    background-color: #f8f9fa;
    color: #495057;
    transition: all 0.2s ease;
    cursor: pointer;
}

.quick-prompt-button:hover {
    background-color: #e9ecef;
    border-color: #ced4da;
}

.quick-prompt-button:active {
    background-color: #dee2e6;
}