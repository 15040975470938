/* Textarea styles */
.result-textarea,
.conclusion-textarea {
    font-family: monospace;
}

/* Markdown container styles */
.markdown-wrapper {
    padding: 1rem;
    background-color: #fff;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
}

.markdown-content {
    line-height: 1.6;
}

/* Markdown element styles */
.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.markdown-content h1 {
    font-size: 1.5rem;
}

.markdown-content h2 {
    font-size: 1.2rem;
}

.markdown-content h3 {
    font-size: 1rem;
}

.markdown-content p {
    margin-bottom: 1rem;
}

.markdown-content ul,
.markdown-content ol {
    margin-bottom: 1rem;
    padding-left: 2rem;
}

.markdown-content code {
    background-color: #f8f9fa;
    padding: 0.2rem 0.4rem;
    border-radius: 0.25rem;
}

.markdown-content blockquote {
    border-left: 4px solid #dee2e6;
    padding-left: 1rem;
    margin-left: 0;
    color: #6c757d;
}

/* Table styles */
.markdown-content table {
    width: 100%;
    margin: 1.5rem 0;
    border-collapse: collapse;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    font-size: 0.9rem;
}

.markdown-content table thead tr {
    background-color: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
}

.markdown-content table th {
    padding: 0.75rem;
    font-weight: 600;
    text-align: left;
    color: #495057;
}

.markdown-content table td {
    padding: 0.75rem;
    border-bottom: 1px solid #e9ecef;
    color: #212529;
}

.markdown-content table tbody tr:last-child td {
    border-bottom: none;
}

.markdown-content table tbody tr:hover {
    background-color: #f8f9fa;
    transition: background-color 0.2s ease;
}

/* Responsive table */
@media screen and (max-width: 768px) {
    .markdown-content table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
    }
}

/* Section specific styles */
.results-section,
.conclusion-section {
    margin-bottom: 2rem;
}