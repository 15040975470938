/* Styles généraux pour la modal */
.generic-modal .modal-dialog {
    max-width: 80%;
    width: 80%;
    margin: 1.75rem auto;
}

.generic-modal .modal-content {
    border-radius: 8px;
    border: none;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

/* Styles pour le mode plein écran */
.generic-modal.modal-fullscreen .modal-dialog {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.generic-modal.modal-fullscreen .modal-content {
    height: 100%;
    max-height: 100%;
    border: 0;
    border-radius: 0;
}

/* En-tête de la modal */
.generic-modal .modal-header {
    background-color: #f7f7f7;
    border-bottom: 1px solid #e0e0e0;
    padding: 1.25rem 1.5rem;
}

.generic-modal-title {
    font-size: 1.5rem;
    color: #333;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}

/* Corps de la modal */
.generic-modal-content {
    background-color: #ffffff;
    padding: 1.5rem !important;
    font-size: 0.9375rem;
    color: #333;
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
}

.generic-modal-content.no-padding {
    padding: 0 !important;
}

/* Styles des formulaires */
.generic-modal .form-label {
    font-weight: 500;
    color: #4a4a4a;
    margin-bottom: 0.5rem;
}

.generic-modal .form-control {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0.5rem 0.75rem;
    font-size: 0.9375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.generic-modal .form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Styles des boutons */
.generic-modal .btn {
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.generic-modal .btn-primary {
    background-color: #0056b3;
    border-color: #0056b3;
}

.generic-modal .btn-primary:hover {
    background-color: #004494;
    border-color: #004494;
}

/* Styles pour la barre de défilement personnalisée */
.generic-modal-content::-webkit-scrollbar {
    width: 8px;
}

.generic-modal-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.generic-modal-content::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
}

.generic-modal-content::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}

/* Styles pour les écrans plus petits */
@media (max-width: 992px) {
    .generic-modal .modal-dialog {
        max-width: 95%;
        width: 95%;
    }
}

@media (max-width: 576px) {
    .generic-modal .modal-dialog {
        margin: 0.5rem;
    }
    
    .generic-modal .modal-content {
        border-radius: 0;
    }

    .generic-modal-title {
        font-size: 1.25rem;
    }

    .generic-modal-content {
        font-size: 0.875rem;
    }
}