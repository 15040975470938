/* App.css */
@import 'variables.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Utility classes */
.cursor-pointer, .cursorHover:hover {
  cursor: pointer;
}

.soft-grey { color: var(--color-soft-grey); }
.soft-pink { color: var(--color-soft-pink); }
.dark-pink { color: var(--color-dark-pink); }
.dark-grey { color: var(--color-dark-grey); }
.icon-light-grey { color: var(--color-icon-light-grey); }
.icon-grey { color: var(--color-icon-grey); }

.icon-spacing { margin-right: var(--spacing-md); }
.icon-spacing-10 { margin-right: var(--spacing-sm); }
.padding-left-10 { padding-left: var(--spacing-sm); }

/* Button styles */
.pink-button, .grey-button, .black-button {
  transition: all 0.3s ease !important;
  color: #fff !important;
}

.pink-button {
  background-color: var(--color-soft-pink) !important;
  border-color: var(--color-soft-pink) !important;
}
.pink-button:hover {
  background-color: var(--color-dark-pink) !important;
  border-color: var(--color-dark-pink) !important;
}

.grey-button {
  background-color: #dddddd !important;
  border-color: #dddddd !important;
}
.grey-button:hover {
  background-color: var(--color-soft-pink) !important;
  border-color: var(--color-soft-pink) !important;
}

.black-button {
  background-color: #000 !important;
  border-color: #000 !important;
}
.black-button:hover {
  background-color: var(--color-soft-pink) !important;
  border-color: var(--color-soft-pink) !important;
}
