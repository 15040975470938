/* Le conteneur principal qui contient la sidebar et la zone de chat */
.copilot-container {
    height: calc(100vh - 79px);
    display: flex;
    flex-direction: row;  /* Layout horizontal */
    overflow: hidden;
}

/* La zone de chat à droite de la sidebar */
.copilot-chat-container {
    flex: 1;  /* Prend tout l'espace restant */
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 0; /* Important pour éviter le débordement du contenu */
}

.chat-messages-area {
    flex: 1;
    overflow-y: auto;
    padding: 2rem 1.5rem;
    background-color: #f8f9fa;
    min-height: 0;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive design pour mobile */
@media (max-width: 768px) {
    .copilot-container {
        position: relative;
    }
    
    .thread-sidebar {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1000;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
    }
    
    .thread-sidebar.show {
        transform: translateX(0);
    }
    
    .copilot-chat-container {
        width: 100%;
    }
}